<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="main-content">
      <div class="barrage-wrap">
        <vue-baberrage :arr="barrageList" :isPause="isPause" :percent="150"></vue-baberrage>
      </div>
      <div class="footer">
        <div class="write-message-button" @click="toNextPage('leave-message')">
          <div class="animation-block-1">
            <div class="text">写留言</div>
          </div>
          <div class="animation-block-2">
            <img src="@/assets/images/border_top_right.png" />
          </div>
          <div class="animation-block-3"></div>
        </div>
        <div class="other-href" @click="toNextPage('/message-all')">查看所有留言</div>
        <br />
        <div class="other-href" @click="toNextPage('/main')">返回主页</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import VueBaberrage from "@/components/vue-baberrage";
function cutString(str, len) {
  //length属性读出来的汉字长度为1
  if (str.length * 2 <= len) {
    return str;
  }
  var strlen = 0;
  var s = "";
  for (var i = 0; i < str.length; i++) {
    s = s + str.charAt(i);
    if (str.charCodeAt(i) > 128) {
      strlen = strlen + 2;
      if (strlen >= len) {
        return s.substring(0, s.length - 1) + "...";
      }
    } else {
      strlen = strlen + 1;
      if (strlen >= len) {
        return s.substring(0, s.length - 2) + "...";
      }
    }
  }
  return s;
}
export default {
  data() {
    return {
      barrageList: [],
      isPause: false,
      pageParams: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  watch: {
    "barrageList.length": function (len) {
      if (len == 0) {
        setTimeout(() => {
          this.getAllMessage();
        }, 3000);
      }
    },
  },
  created() {
    this.getAllMessage();
  },
  // activated() {
  //   this.isPause = true;
  // },
  // deactivated() {
  //   this.isPause = true;
  // },
  methods: {
    getAllMessage() {
      let self = this;
      api.getBarrage(this.pageParams).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          setTimeout(() => {
            const comment = res.list[i];
            this.barrageList.push({
              direction: "default",
              content:
                cutString(comment.content, 45) + "   ---" + comment.leave_name,
            });
          }, Math.random() * 100);
        }

        if (res.list.length < self.pageParams.pageSize) {
          self.pageParams.page = 1;
        } else {
          self.pageParams.page++;
        }
      });
    },
    toNextPage(url) {
      this.$router.push(url);
    },
  },
  components: {
    VueBaberrage,
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }

  .main-content {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 20px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-flow: column;
    .barrage-wrap {
      flex: 1;
      position: relative;
      & > div {
        position: absolute;
      }
    }
    .footer {
      text-align: center;
      position: relative;
      z-index: 99;
      .write-message-button {
        width: 145px;
        height: 72px;
        margin: 10px auto;
        position: relative;

        .animation-block-1 {
          position: absolute;
          z-index: 13;
          width: 145px;
          height: 66px;
          line-height: 66px;
          top: 6px;
          left: 0px;
          text-align: center;
          animation: 2s ease 0.8s 1 normal both running fadeInUp;
          .text {
            font-family: Tahoma, Helvetica, Arial;
            font-size: 22px;
            color: rgb(7, 121, 137);
            font-weight: 700;
          }
        }
        .animation-block-2 {
          position: absolute;
          z-index: 12;
          width: 117px;
          height: 62.5px;
          top: 0px;
          left: 34px;
          text-align: left;
          animation: 2s ease 0.6s 1 normal both running rollInLeft;
          img {
            height: 100%;
          }
        }
        .animation-block-3 {
          position: absolute;
          z-index: 12;
          width: 145px;
          height: 62.5px;
          top: 6px;
          left: 0px;
          text-align: left;
          animation: 2s ease 0.6s 1 normal both running rollInRight;
          background-color: #f4c251;
        }
      }
      .other-href {
        font-size: 14px;
        color: rgb(255, 255, 255);
        text-decoration-line: underline;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        padding: 8px 16px;
        & + .other-href {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>